<template>
  <b-tooltip
    class="font-Inter"
    :target="targetId"
    placement="bottom"
    triggers="hover"
  >
    <b-link
      class="help_link"
      :href="HELP_URL"
    >
      Schedule a meeting
    </b-link>
    &nbsp;&nbsp;with Kingpin to request the brand’s presence in your country.
  </b-tooltip>
</template>

<script>
import { BLink, BTooltip } from 'bootstrap-vue'
import { HELP_URL } from '@/constants'

export default {
  name: 'TooltipAddToCartBtn',
  components: {
    BTooltip,
    BLink,
  },
  props: {
    targetId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      HELP_URL: HELP_URL(),
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';

.help_link {
  color: $blue;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
</style>
