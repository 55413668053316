<template>
  <div
    class="sidebar-detached sidebar-left"
    :class="{ 'filter-for-explore': isExplore }"
  >
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{ show: mqShallShowLeftSidebar }"
      >
        <!-- Filters' Card -->
        <b-card
          id="intro_filters"
          no-body
        >
          <b-card-body class="pt-0">
            <!-- Departments -->
            <div
              v-if="target === 'shop'"
              class="d-flex d-lg-none mt-1 justify-content-end"
            >
              <feather-icon
                icon="XIcon"
                size="24"
                class="cursor-pointer"
                @click="$emit('close-sidebar')"
              />
            </div>
            <div v-if="!isExplore">
              <div class="mt-1 d-flex align-items-center">
                <b-img
                  :src="filterIcon"
                  alt="Error Icon"
                />
                <span class="shop-filter"> Filter </span>
                <span
                  class="
                    position-absolute position-right-0
                    mr-1
                    color-blue
                    cursor-pointer
                  "
                  @click="resetFilters"
                >
                  Reset Filters
                </span>
              </div>
              <div class="d-none d-lg-block">
                <span
                  v-if="totalProducts >= 0"
                  class="font-size-14-px"
                >{{ totalProducts || 0 }}
                  {{ filterOptions.sizes ? 'product' : 'brand'
                  }}{{ totalProducts > 1 ? 's' : '' }} found</span>
              </div>
            </div>
            <div
              v-if="filterOptions.lines && filterOptions.lines.length"
              class="product-categories"
            >
              <h6 class="filter-title mt-2">
                Lines
              </h6>
              <b-form-checkbox-group
                v-model="filters.lines"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.lines, showMoreLines)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.lines.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreLines = !showMoreLines"
                >Show {{ showMoreLines ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <div
              v-if="filterOptions.departments && filterOptions.departments.length"
              class="product-categories"
            >
              <h6 class="filter-title mt-2">
                Departments
              </h6>
              <b-form-checkbox-group
                v-model="filters.departments"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.departments, showMoreDepartments)"
                @change="updateFilters"
              />
            </div>
            <div>
              <span
                v-if="filterOptions.departments && filterOptions.departments.length > GROUP_MAX_SHOWING_COUNT"
                class="read-more"
                @click="showMoreDepartments = !showMoreDepartments"
              >Show {{ showMoreDepartments ? 'Less' : 'More' }}
              </span>
            </div>
            <!-- Brand Collection types Filters -->
            <div
              v-if="target === ROLES.BRAND"
              class="product-categories"
            >
              <h6 class="filter-title">
                Collection Type
              </h6>
              <b-form-checkbox-group
                v-model="filters.collectionType"
                class="categories-radio-group"
                stacked
                :options="filterOptions.collectionTypes"
                @change="updateFilters"
              />
            </div>
            <!-- Categories -->
            <div
              v-if="filterOptions.categories"
              class="product-categories"
            >
              <h6 class="filter-title">
                Categories
              </h6>
              <b-form-checkbox-group
                v-model="filters.categories"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.categories, showMoreCategories)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.categories.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreCategories = !showMoreCategories"
                >Show {{ showMoreCategories ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Sub Categories -->
            <div
              v-if="filterOptions.subCategories"
              class="product-categories"
            >
              <h6 class="filter-title">
                Sub categories
              </h6>
              <b-form-checkbox-group
                v-model="filters.subCategories"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.subCategories, showMoreSubCategory)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.subCategories.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreSubCategory = !showMoreSubCategory"
                >Show {{ showMoreSubCategory ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Gender -->
            <div
              v-if="filterOptions.gender"
              class="product-categories"
            >
              <h6 class="filter-title">
                Gender
              </h6>
              <b-form-checkbox-group
                v-model="filters.gender"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.gender, showMoreGender)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.gender.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreGender = !showMoreGender"
                >Show {{ showMoreGender ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Color Family-->
            <div
              v-if="filterOptions.colors"
              class="product-categories"
            >
              <h6 class="filter-title">
                Color Family
              </h6>
              <b-form-checkbox-group
                v-model="filters.colors"
                class="categories-radio-group"
                stacked
                @change="updateFilters"
              >
                <b-form-checkbox
                  v-for="color in sortFilterOptions(filterOptions.colors, showMoreColorFamily)"
                  :key="color"
                  :value="color"
                >
                  <div class="d-flex">
                    <div
                      v-if="getColorCode(color)"
                      class="product-color align-self-center"
                      :style="'background:' + getColorCode(color)"
                    />
                    <div class="align-self-center">
                      {{ color }}
                    </div>
                  </div>
                </b-form-checkbox>
                <div>
                  <span
                    v-if="filterOptions.colors.length > GROUP_MAX_SHOWING_COUNT"
                    class="read-more"
                    @click="showMoreColorFamily = !showMoreColorFamily"
                  >Show {{ showMoreColorFamily ? 'Less' : 'More' }}
                  </span>
                </div>
              </b-form-checkbox-group>
            </div>
            <!-- Size -->
            <div
              v-if="filterOptions.sizes && filterOptions.sizes.length"
              class="product-categories"
            >
              <h6 class="filter-title">
                Sizes
              </h6>
              <b-form-checkbox-group
                v-model="filters.sizes"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.sizes, showMoreSizes)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.sizes.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreSizes = !showMoreSizes"
                >Show {{ showMoreSizes ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Sub Line -->
            <div
              v-if="filterOptions.subLines && filterOptions.subLines.length"
              class="product-categories"
            >
              <h6 class="filter-title">
                Sub Lines
              </h6>
              <b-form-checkbox-group
                v-model="filters.subLines"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.subLines, showMoreSubLines)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.subLines.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreSubLines = !showMoreSubLines"
                >Show {{ showMoreSubLines ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Group -->
            <div
              v-if="filterOptions.groups && filterOptions.groups.length"
              class="product-categories"
            >
              <h6 class="filter-title">
                Groups
              </h6>
              <b-form-checkbox-group
                v-model="filters.groups"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.groups, showMoreGroups)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.groups.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreGroups = !showMoreGroups"
                >Show {{ showMoreGroups ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Sub Group -->
            <div
              v-if="filterOptions.subGroups && filterOptions.subGroups.length"
              class="product-categories"
            >
              <h6 class="filter-title">
                Sub Groups
              </h6>
              <b-form-checkbox-group
                v-model="filters.subGroups"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.subGroups, showMoreSubGroups)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.subGroups.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreSubGroups = !showMoreSubGroups"
                >Show {{ showMoreSubGroups ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Key Styles -->
            <div
              v-if="filterOptions.keyStyles && filterOptions.keyStyles.length"
              class="product-categories"
            >
              <h6 class="filter-title">
                key Styles
              </h6>
              <b-form-checkbox-group
                v-model="filters.keyStyles"
                class="categories-radio-group"
                stacked
                :options="sortFilterOptions(filterOptions.keyStyles, showMoreKeyStyles)"
                @change="updateFilters"
              />
              <div>
                <span
                  v-if="filterOptions.keyStyles.length > GROUP_MAX_SHOWING_COUNT"
                  class="read-more"
                  @click="showMoreKeyStyles = !showMoreKeyStyles"
                >Show {{ showMoreKeyStyles ? 'Less' : 'More' }}
                </span>
              </div>
            </div>
            <!-- Price -->
            <div
              v-if="
                filterOptions.retailPriceMin >= 0 &&
                  filterOptions.retailPriceMax >= 0
              "
              class="product-categories"
            >
              <h6 class="filter-title">
                Retail Prices
              </h6>
              <vue-slider
                v-model="filterOptions.retailPrice"
                :min="filterOptions.retailPriceMin"
                :max="filterOptions.retailPriceMax"
                :tooltip-formatter="(v) => `$${v}`"
                direction="ltr"
                :interval="1"
                @change="updatePriceFilters"
              >
                <div
                  slot="label"
                  slot-scope="props"
                  class="sliderLabel"
                >
                  <span v-if="props.index === filterOptions.retailPriceMin">{{
                    props.label
                  }}</span>
                  <span v-if="props.index === filterOptions.retailPriceMax">{{
                    props.label
                  }}</span>
                </div>
              </vue-slider>
            </div>
            <div
              v-if="
                filterOptions.wholesalePriceMin >= 0 &&
                  filterOptions.wholesalePriceMax >= 0 &&
                  wholeSaleEnable
              "
              class="product-categories mt-1"
            >
              <h6>Wholesale Prices</h6>
              <vue-slider
                v-model="filterOptions.wholesalePrice"
                :min="filterOptions.wholesalePriceMin"
                :max="filterOptions.wholesalePriceMax"
                :tooltip-formatter="(v) => `$${v}`"
                direction="ltr"
                :interval="1"
                @change="updatePriceFilters"
              />
            </div>
            <!-- Brand Price Filters -->
            <div
              v-if="target === ROLES.BRAND && filterOptions.retailPrices"
              class="product-categories"
            >
              <h6 class="filter-title">
                Retail Prices
              </h6>
              <b-form-checkbox-group
                v-model="filters.retailPrice"
                class="categories-radio-group"
                stacked
                :options="filterOptions.retailPrices"
                @change="updateFilters"
              />
            </div>
            <div
              v-if="
                target === ROLES.BRAND &&
                  filterOptions.wholesalePrices &&
                  !isGuest
              "
              class="product-categories"
            >
              <h6 class="filter-title">
                Wholesale Prices
              </h6>
              <b-form-checkbox-group
                v-model="filters.wholesalePrice"
                class="categories-radio-group"
                stacked
                :options="filterOptions.wholesalePrices"
                @change="updateFilters"
              />
            </div>
            <div
              v-if="
                target === ROLES.BRAND && filterOptions.avgMarkup && !isGuest
              "
              class="product-categories"
            >
              <h6 class="filter-title">
                Average markup
              </h6>
              <b-form-checkbox-group
                v-model="filters.avgMarkup"
                class="categories-radio-group"
                stacked
                :options="filterOptions.avgMarkup"
                @change="updateFilters"
              />
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { getColorCode } from '@/product-colors'
import store from '@/store'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
BCard,
BCardBody,
BFormCheckbox,
BFormCheckboxGroup,
BImg,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'

const { ROLES } = c

export default {
  components: {
    BFormCheckbox,
    BFormCheckboxGroup,
    BCard,
    BCardBody,
    BImg,

    // 3rd Party
    VueSlider,
    FeatherIcon,
  },
  emits: ['update-filters', 'close-sidebar'],
  props: {
    target: {
      type: String,
      default: 'shop',
    },
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    wholeSaleEnable: {
      type: Boolean,
      default: true,
    },
    totalProducts: {
      type: Number,
      default: 0,
    },
    isExplore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priceFilterTimeout: null,
      getColorCode,
      filterIcon: require('@/assets/images/icons/filterIcon.svg'),
      ROLES,
      GROUP_MAX_SHOWING_COUNT: 5,
      showMoreDepartments: false,
      showMoreCategories: false,
      showMoreSubCategory: false,
      showMoreGender: false,
      showMoreColorFamily: false,
      showMoreSizes: false,
      showMoreLines: false,
      showMoreSubLines: false,
      showMoreGroups: false,
      showMoreSubGroups: false,
      showMoreKeyStyles: false,
    }
  },
  computed: {
    isGuest() {
      return store.getters.isGuest
    },
  },
  methods: {
    // function for sorting filter options
    sortFilterOptions(options, show = false) {
      if (show) {
        return options.filter(menu => menu.length > 0).sort()
      }
      return options.filter(menu => menu.length > 0).sort().slice(0, this.GROUP_MAX_SHOWING_COUNT)
    },
    updatePriceFilters() {
      if (this.priceFilterTimeout) clearTimeout(this.priceFilterTimeout)
      this.priceFilterTimeout = setTimeout(() => {
        this.updateFilters()
      }, 1000)
    },
    updateFilters() {
      if (this.target === 'shop') {
        this.filters.wholesalePriceMin = this.filterOptions?.wholesalePrice[0]
        this.filters.wholesalePriceMax = this.filterOptions?.wholesalePrice[1]
        this.filters.retailPriceMin = this.filterOptions?.retailPrice[0]
        this.filters.retailPriceMax = this.filterOptions?.retailPrice[1]
      }
      this.$emit('update-filters', this.filters)
    },
    resetFilters() {
      const _filters = {}
      for (const key in this.filters) {
        if (this.filters.hasOwnProperty(key)) {
          if (Array.isArray(this.filters[key])) {
            _filters[key] = []
          } else if (typeof this.filters[key] === 'object') {
            _filters[key] = {}
          } else if (typeof this.filters[key] === 'string') {
            _filters[key] = ''
          } else {
            _filters[key] = null
          }
        }
      }
      this.$emit('update-filters', _filters, true)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/vue/libs/vue-slider.scss';

.categories-radio-group {
            .custom-control-input:checked ~ .custom-control-label::before,
            .custom-control-input:active ~ .custom-control-label::before,
            .custom-control-input:focus ~ .custom-control-label::before {
              box-shadow: unset !important;
            }
}

</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce.scss';

.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.filter-for-explore {
  .filter-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $body-color;
    margin-top: 16px !important;
  }

  .categories-radio-group {
    label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 21px !important;
      color: $body-color !important;
    }
  }
}
.read-more {
  font-weight: bold;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
}
</style>
