const colorCode = {
  BEIGE: '#CEC4AD',
  BLUSH: '#DE5D83',
  BLUE: '#1CBFFF',
  BROWN: '#805F3A',
  GREEN: '#1C8C38',
  CAMEL: '#C19A6B',
  CHARCOAL: '#36454F',
  CREAM: '#FFFDD0',
  DENIM: '#1560BD',
  LILAC: '#C8A2C8',
  MULTI: 'linear-gradient(135deg, #FA3232 17.19%, #FFBA34 27.34%, #FFF612 39.06%, #A3FF5A 49.61%, #3FF5CA 59.77%, #16BFFB 68.75%, #3F72F5 77.34%, #AF2CFF 87.11%)',
  STONE: '#DDC7A7',
  STRIPE: '#008cdd',
  WINE: '#722F37',
  GOLD: 'linear-gradient(0deg, #B8A77B, #B8A77B), linear-gradient(137.6deg, #ABABAB 15.77%, #BCBCBC 26.89%, #DADADA 50.57%, #E9E9E9 61.7%, #C4C4C4 72.46%, #8E8D8D 84.66%)',
  SILVER: 'linear-gradient(137.6deg, #ABABAB 15.77%, #BCBCBC 26.89%, #DADADA 50.57%, #E9E9E9 61.7%, #C4C4C4 72.46%, #8E8D8D 84.66%)',
  YELLOW: '#FFE433',
  GREY: '#9C9C9C',
  ORANGE: '#FFB021',
  PINK: '#FFA1EA',
  PURPLE: '#6A36E3',
  RED: '#F54043',
  REGULAR: '',
  LONG: '',
}

export const getColorCode = color => {
  if (color && colorCode.hasOwnProperty(color.toUpperCase())) {
    const colorString = color.toUpperCase()
    if (colorCode[colorString] === '') {
      return null
    }
    return colorCode[colorString]
  }
  return color
}
export const classForWhiteColor = color => {
  if (color && color.toLowerCase() === 'white') {
    return 'border-cader-gray'
  }
}
