<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H1.81879C2.12008 1 2.38297 1.20263 2.46095 1.49328L2.68721 2.3422M2.68721 2.3422C5.97812 2.24998 9.26586 2.61582 12.456 3.4292C11.9692 4.87892 11.3909 6.28729 10.7286 7.64604H4.10149M2.68721 2.3422L4.10149 7.64604M4.10149 7.64604C3.63145 7.64604 3.18066 7.83276 2.8483 8.16513C2.51593 8.49749 2.32921 8.94828 2.32921 9.41832H11.6337M3.21535 11.1906C3.21535 11.3081 3.16867 11.4208 3.08557 11.5039C3.00248 11.587 2.88979 11.6337 2.77228 11.6337C2.65477 11.6337 2.54207 11.587 2.45898 11.5039C2.37589 11.4208 2.32921 11.3081 2.32921 11.1906C2.32921 11.0731 2.37589 10.9604 2.45898 10.8773C2.54207 10.7942 2.65477 10.7475 2.77228 10.7475C2.88979 10.7475 3.00248 10.7942 3.08557 10.8773C3.16867 10.9604 3.21535 11.0731 3.21535 11.1906ZM10.7475 11.1906C10.7475 11.3081 10.7008 11.4208 10.6178 11.5039C10.5347 11.587 10.422 11.6337 10.3045 11.6337C10.1869 11.6337 10.0742 11.587 9.99116 11.5039C9.90807 11.4208 9.86139 11.3081 9.86139 11.1906C9.86139 11.0731 9.90807 10.9604 9.99116 10.8773C10.0742 10.7942 10.1869 10.7475 10.3045 10.7475C10.422 10.7475 10.5347 10.7942 10.6178 10.8773C10.7008 10.9604 10.7475 11.0731 10.7475 11.1906Z"
      stroke="black"
      stroke-width="0.787679"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</template>

<script>
export default {
  name: 'ShoppingCartIcon',
}
</script>
