<template>
  <div>
    <b-modal
      id="hint_selection_modal"
      centered
      modal-class="custom-modal-variant"
      header-close-variant="cader-gray"
      hide-footer
    >
      <template #modal-title>
        <div class="elements-center color-dark-blue">
          <span> Add products to Selection </span>
        </div>
      </template>
      <div class="mb-2 order-download-modal">
        <div class="w-100">
          <div class="px-3 d-flex flex-column justify-content-center text-primary font-weight-bold">
            <div class="d-flex align-items-center">
              <span>
                Tick Products
              </span>
              <b-form-checkbox
                class="ml-5-px"
                :checked="true"
                disabled
              />
            </div>
            <div>
              and move them to the Selection in one click!
            </div>
          </div>
          <div class="text-center mt-2">
            <b-button
              class="px-3"
              variant="info"
              @click="onClickOk"
            >
              Ok
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'AppProductsSelectionHintModal',
  components: {
    BModal,
    BButton,
    BFormCheckbox,
  },
  methods: {
    onClickOk() {
       this.$bvModal.hide('hint_selection_modal')
    },
  },
}
</script>
